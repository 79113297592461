.evento {
    // width: 100%;
    // max-width: 300px;
    break-inside: avoid-column;
    -webkit-column-break-inside: avoid;
    margin-bottom: 5px;

    .opcoes {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;

        button {
            background: none;
            border: 0;
            color: rgb(182, 182, 182);

            &:focus {
                outline: 0
            }

            &:hover {
                color: rgb(97, 97, 97);
            }

        }
    }
}