.categoria {
    color: #535353;

    .icone {
        margin-right: 5px;
    }
}

.cabecalho {
    margin-bottom: 0;
    background: white !important;
    border-bottom: 0 !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;

    .titulo {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
    }
}

.tituloPermissao {
    font-style: italic;
    font-size: 16px;
    font-weight: 400;
}

.inputCheck {
    
    input {
        margin-top: 0.45rem !important;
        cursor: pointer;
    }

    label {
        cursor: pointer;
    }
}