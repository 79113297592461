.option {
    margin: 0;
    padding: 2px 6px;
    p {
        margin: 0;
    }
    border-bottom: 1px solid rgb(228, 228, 228);

    .dadosUsuario {
        display: flex;

        img {
            max-width: 50px;
            height: 50px;
            width: 50px;
            margin-right: 5px;
            object-fit: cover;
        }
    }

    &:hover {
        background: rgb(82, 82, 255);
        color: white;
    }

    // &:active {
    //     // background: red !important;
    //     background-color: rgb(178, 212, 255);
    // }
}

.focado {
    background: rgb(213, 213, 255);;
}