
.painel-navbar {
    -webkit-font-smoothing: antialiased;
    font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
    font-weight: 400;
    transition: all 300ms linear;
    border: 0;
    font-size: 16px;
    border-radius: 0;
    min-height: 50px;
    background-color: rgba(255, 255, 255, 0.96);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 15px;
    margin-bottom: 0;

    &.navbar {
        border: 0;
        font-size: 16px;
        border-radius: 0;
        min-height: 50px;
        background-color: rgba(255, 255, 255, 0.96);
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 5px 15px;
    }

    .navbar-brand {
        -webkit-font-smoothing: antialiased;
        font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
        font-weight: 400;
        font-weight: 400;
        margin: 5px 0px;
        font-size: 20px;
        color: #888888;
    }

    .navbar-nav {
        align-items: center;

        .nav-item {

            .nav-link {
                -webkit-font-smoothing: antialiased;
                font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
                font-weight: 400;
                color: #888888;
                padding: 10px 15px;
                position: relative;
                display: inline-flex;
                line-height: 1.2;

                &:hover {
                    color: #1DC7EA;
                    cursor: pointer;
                }
    
                .icone {
                    display: inline-block;
                    font: normal normal normal 14px/1 'nucleo-icons';
                    font-size: inherit;
                    speak: none;
                    text-transform: none;
                    -webkit-font-smoothing: antialiased;
                    font-weight: 700;
                }

                &.nav-link-dropdown {
                    color: #888888;
                    padding: 10px 15px;
                    position: relative;
                    display: inline-flex;
                    line-height: 1.2;
                    padding: 0;

                    &:after {
                        display: inline-block;
                        width: 0;
                        height: 0;
                        margin-left: 5px;
                        margin-top: 12px;
                        vertical-align: middle;
                        border-top: 4px dashed;
                        border-top: 4px solid\9;
                        border-right: 4px solid transparent;
                        border-left: 4px solid transparent;
                        margin-top: 8px;
                    }

                    .icone {
                        display: inline-block;
                        font: normal normal normal 14px/1 'nucleo-icons';
                        font-size: inherit;
                        speak: none;
                        text-transform: none;
                        -webkit-font-smoothing: antialiased;
                        font-weight: 700;
                        font-size: inherit;
                    }

                    .caret {

                    }

                    .notification {
                        position: absolute;
                        background-color: #FB404B;
                        text-align: center;
                        border-radius: 10px;
                        min-width: 18px;
                        padding: 0 5px;
                        height: 18px;
                        font-size: 12px;
                        color: #FFFFFF;
                        font-weight: bold;
                        line-height: 18px;
                        top: 0;
                        left: 7px;
                    }
                }
    
            }

        }
    }
}