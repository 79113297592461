.painel-pagina {
    --width-sidebar: 290px;
    position: relative;
    top: 0;
    height: 100vh;

    &.menu-encolher {
        --width-sidebar: 90px;
    }

    .painel-pagina-corpo {
        background: rgba(203, 203, 210, 0.15);
        position: relative;
        float: right;
        transition: width 2s;
        width: calc(100% - var(--width-sidebar));
        min-height: 100%;
        overflow: auto;
        max-height: 100%;
        height: 100%;
        transition-property: top, bottom;
        transition-duration: .2s, .2s;
        transition-timing-function: linear, linear;
        transition: width 0.3s;

        > .content {
            padding: 30px 15px;
            min-height: calc(100% - 50px);
        }

        &.tela-mapas {

            > .content {
                padding: 0;
                margin: 0;
                position: relative;

                .container-fluid {
                    padding: 0;
                    margin: 0;
                    min-height: calc(100% - 100px);
                    
                }
            }

        }
    }
}