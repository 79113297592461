.mes {
    // background: red;
    margin-bottom: 10px;;

    .mesLabel {
        display: flex;
        align-items: 'center';

        .labelIcone {
            width: 20%;
            position: relative;

            .circulo {
                position: absolute;
                background: rgb(230, 230, 230);
                border: 2px solid #ff5959;
                border-radius: 20px;
                width: 40px;
                height: 40px;
                right: 0;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }

            .linha {
                position: absolute;
                background:#ff5959;
                // border: 3px solid red;
                // border-radius: 20px;
                width: 3px;
                height: 100%;
                right: 0;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }

            .linhaHorizontal {
                position: absolute;
                background:#ff5959;
                // border: 3px solid red;
                // border-radius: 20px;
                width: 50%;
                // height: 1px;
                border-top: 1px solid #ff5959;
                right: 0;
                left: 50%;
                // top: 0;
                top: 0;
                // margin: auto;
            }
        }

        .labelDescricao {
            width: 80%;
            min-height: 60px;
            display: flex;
            align-items: center;
            font-weight: bold;
            border-top: 1px solid #ff5959;
            border-top-right-radius: 20px;
        }
    }

}