.cartoes {
    display: flex;
    align-items: center;
    justify-content: center;

    &.mobile {

        flex-wrap: wrap;

        .cartao {

            img {
                height: 50px;
                width: 50px;
                padding: 5px;
            }
        }
    }

    .cartao {
        cursor: pointer;
        border-radius: 10px;

        &.ativo {
            background: #e5e5e5;
            box-shadow: 1px 1px 4px #cecece;
            

            img {
                opacity: 1;
            }
        }

        &:hover {
            box-shadow: 1px 1px 4px #e2e2e2;
            background: #f0f0f0;

            
        }

        img {
            height: 100px;
            width: 100px;
            object-fit: contain;
            padding: 10px;
            opacity: 0.7;
        }
    }
}