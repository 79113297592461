.containerImg {
    display: flex;
    align-items: center;
    justify-content: center;
}

.containerPadrao {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 2px;
}

.containerTitulo {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 2px;
    font-size: 1.3rem;
    font-weight: 600;
}

.link {
    font-size: 1.1rem;
    font-weight: 600;
    text-decoration: underline;
    color: black;
    
    &:hover {
        text-decoration: underline;
        color: black;
        opacity: 0.8;
    }
}