.cardAniversariante {
    // width: 100%;
    position: relative;

    &:hover {
        background: rgb(245, 245, 245);
        background: white;

        .info {

            .dados {

                .idade {

                    button {
                        display: block;
                    }
                }
            }
        }
    }

    &.parabenizar {
        background: #3fff001a;
    }

    .nome {
        // display: inline-block;
    }

    .info {
        display: flex;
        align-items: flex-start;

        

        .balao {
            position: absolute;
            max-height: 100%;
            width: 27px;
            top: -8px;
            left: -8px;
        }

        .foto {
            width: 50px;
            height: 50px;
            margin-right: 7px;
            border-radius: 25px;
            object-fit: cover;
            vertical-align: middle;
            // border: 1px solid grey;
            box-shadow: 0 0 2px rgb(177, 177, 177);
            cursor: pointer;

            &:hover {
                box-shadow: 0 0 5px rgb(155, 155, 155);
            }
        }

        .dados {
            flex-grow: 1;
            width: 100%;
            overflow: hidden;

            .nome {
                display: block;
            }

            .idade {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                button {
                    display: none;
                    background: none;
                    border: 0;
                    height: auto;
                    color: grey;

                    &:hover {
                        color: rgb(56, 56, 56);
                    }

                    &:focus {
                        outline: 0;
                    }

                    .icone {
                        font-size: 14px;
                    }
                }

                .emailEnviado {
                    color: rgb(0, 207, 0);
                    display: block;

                    &:hover {
                        color: rgb(0, 119, 0);
                    }
                }
            }
        }
    }
}