.filtros {
    // justify-content: space-between;

    .campoDataContainer {
        display: flex;
        align-items: center;

        .icone {
            margin-right: 4px;
        }

        .campoData {
            // max-width: 300px;
            text-align: center;
            font-weight: bold;
            background: transparent;
            border: none;
        }
    }
}