.container {
    padding: 5px;

    .aniversariantes {
    
        // overflow-y: scroll;
        // height: 100%;

        .data {
            text-align: right;
            border-top: 1px solid rgb(221, 221, 221);
            font-weight: bold;
            color: rgb(77, 77, 77);;
        }
    }
}