.container {
    position: relative;
    height: 100%;

    .padrao {
        display: flex;
        align-items: center;
        justify-items: center;
        height: 100%;

        .tabua {
            cursor: pointer;
            background: rgb(218, 217, 215);
            padding: 2px 3px;
            width: 30px;
            height: 26px;

            .paleta {
                width: 100%;
                height: 100%;
            }
        }

    }

    .picker {
        position: absolute !important;
        left: -70px;
        z-index: 1;
    }

}