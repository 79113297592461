.arquivos {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    // variaveis
    // --width-arquivo: calc(50% - 4px);

    /*
    .arquivo {
        width: var(--width-arquivo);
        display: inline-flex;
        align-items: flex-start;
        margin-right: 4px;
        margin-bottom: 4px;
        // min-width: 300px;
        // width: calc((25em - 100%) * 1000);
        position: relative;

        &:hover {
            // cursor: pointer;
            background: rgb(245, 245, 245);

            .descricao {

                .opcoes {
                    display: block;
                }
            }
        }

        &.salvando {
            position: relative;
            background: rgb(245, 246, 255);

            &:hover {
                cursor: default;
            }

            .barra {
                position: absolute;
                top: 0;
                bottom:0;
                left: 0;
                right: 0;
                background: rgba(43, 255, 0, 0.58);
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: bold;
                text-shadow: 1px 1px 2px grey;
                min-width: 35px;
                
                span {
                    color: #323232;
                }
            }
        }

        .icone {
            width: 50px;
            height: 50px;
            object-fit: contain;
        }

        .descricao {
            width: calc( 100%);
            display: block;
            

            .nome {
                display: block;
                width: calc( 100% - 50px );
            }

            .opcoes {
                position: absolute;
                bottom: 0;
                right: 0;
                padding: 3px;
                display: none;

                .opcao {
                    background: none;
                    border: 0;
                    box-shadow: 0;

                    &:hover {
                        background: rgb(226, 226, 226);

                        .iconeOpcao {
                            color: rgb(54, 54, 54);
                        }
                    }

                    &:focus {
                        outline: 0;
                    }

                    .iconeOpcao {
                        font-size: 14px;
                        color: rgb(97, 97, 97);
                    }
                }
            }

        }

        
    }
    */
}


// @media only screen and (max-width: 950px) {
//     .arquivos {
//         --width-arquivo: 100%;
//     }
// }