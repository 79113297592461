.cabecalho {
    // position: relative;

    // .tipoCliente {
    //     position: absolute;
    // }

    display: flex;
    align-items: center;
    justify-content: space-between;
    .tipoCliente {
        
    }

    .opcoes {
        display: flex;
        align-items: center;
    }
}


.imagemCliente {
    
    img {
        width: 100%;
    }
}

.responsavelForm {
    position: relative;

    .preCliente {
        position: absolute;
        top: 1px;
        right: 1px;

        input, label {
            cursor: pointer;
        }
    }
}

.toastCliente {
    
}

.containerArquivo {            
    height: 40px;
    border: 1px solid #284787;
    border-right-width: 0px;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.botaoArquivo {

    &:hover {
        background: #4175e1 !important;
    }

}