.clienteFocado {
    position: absolute;
    top: 0.5rem;
    right: 4rem;
    width: 30%;

    .cabecalho {
        background: #fff;
        padding-right: 0px;

        .containerTitulo {
            display: flex;
            overflow: hidden;
            white-space: nowrap;
            width: 280px;
            align-items: center;
            justify-content: flex-start;

            .titulo {
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: large;
                white-space: nowrap;
                text-align: left;
                width: 100%;
            }

        }        

        .btMinimizar {
            
            padding: 5px;
            width: 20px;
            margin-right: 20px;
            text-align: center;
            background: transparent;
            cursor: pointer;
    
            &:hover {
    
                background: rgba(0, 0, 0, 0.4);
    
            }

            &:active {

                background: rgba(0, 0, 0, 0.2);

            }
    
        }

    }

    .identificacao {
        display: flex;
        align-items: flex-start;

        .foto {

            img {
                max-width: 50px;
                height: 50px;
                width: 50px;
                margin-right: 5px;
                object-fit: cover;
            }
        }

        .info {
            flex-grow: 1;

            p {
                margin: 0;
            }
        }
    }

    .opcoes {
        display: flex;
        align-items: flex-end;
    }
}