.modal {
    overflow-y: hidden !important;
}

.container-modal-dados-usuario {
    overflow-y: hidden !important;
}

.dialog-modal-dados-usuario {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}