.funil {
    
    .item {
        display: flex;
        font-weight: 900;
        font-size: 21px;
        align-items: center;
    }

    .label {
        width: 40%;
        white-space: nowrap;

        .labelSpan {
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }
        }

        &:hover {

            .botaoListar {
                // display: inline;
            }
        }
    }

    .valor {
        width: 60%;

        .bloco {
            width: 95%;
            display: block;
            margin: auto;
            color: white;
            text-align: center;
            padding: 8px;
            position: relative;
            cursor: default;

            &:hover {
                // filter: drop-shadow(0 0 3px grey);
                // text-shadow: 0 0 5px grey;
            }

            
            &.b0 {
                width: 100%;

                &::before {
                    content: none;
                }

                &::after {
                    content: none;
                }
            }

            &.b2 {
                width: 80%;
            }

            &.b3 {
                width: 65%;
            }

            &.b4 {
                width: 50%;
            }

            &:before {
                content: '';
                position: absolute;
                top: 0; 
                left: 0;
                border-bottom:47.2px solid white;
                border-right: 15px solid transparent;
            }

            &:after {
                content: '';
                position: absolute;
                top: 0; 
                right: 0;
                border-bottom:47.2px solid white;
                border-left: 15px solid transparent;
            }
        }
    }
}