.nota {

    width: 100%;
    height: auto;
    padding: 1px 7px;
    padding-bottom: 7px;
    break-inside: avoid-column;
    -webkit-column-break-inside: avoid;
    position: relative;

    &.concluido {
        .pin {
            &:after {
                background-color: #00d100;
            }
        }
    }

    &.aVencer {
        .pin {
            &:after {
                background-color: #00bdf7;
            }
        }
    }

    &.venceHoje {
        .pin {
            &:after {
                background-color: #dddd00;
            }
        }
    }

    &.atrasado {
        .pin {
            &:after {
                background-color: #dd0000;
            }
        }
    }

    blockquote {
        // transform: rotate(0.5deg);
        background: #FBF711;
        box-shadow: 0 7px 7px 2px rgba(0, 0, 0, 0.219);
        position: relative;
        margin-bottom: 12px;
        padding: 10px;
        font-size: 14px;

        &:hover {

            .opcoes {
                display: flex;   
    
                button {
                    // display: inline-block;
                }
            }
        }
    
        p {
            margin: 0;
            padding: 0;
        }

        .descricao {
            // word-break: break-all;
            word-wrap: break-word;
        }

        .autor {
            display: block;
            margin: 30px 0 0 0;
            text-align: right;
        }

        .data {
            text-align: right;
            display: block;
            font-size: 11px;
            font-weight: bold;
            text-shadow: 1px 1px 1px rgba(222, 222, 222, 0.42);

            // min-height: 21px;
        }
    }

    .pin {
        background-color: #aaa;
        display: block;
        height: 32px;
        width: 2px;
        position: absolute;
        right: 10px;
        top: 10px;
        transform: rotate(13deg);
        z-index: 2;

        &:after {
            // background-color: #5130aa;
            // background-image: radial-gradient(25% 25%, circle, rgba(255, 255, 255, 0.3), rgba(0, 0, 0, 0.3));
            border-radius: 50%;
            box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1), inset 3px 3px 3px rgba(255, 255, 255, 0.2), inset -3px -3px 3px rgba(0, 0, 0, 0.2), 23px 20px 3px rgba(0, 0, 0, 0.15);
            content: '';
            height: 12px;
            left: -5px;
            position: absolute;
            top: -10px;
            width: 12px;

        }

        &:before {
            background-color: rgba(0, 0, 0, 0.1);
            box-shadow: 0 0 0.25em rgba(0, 0, 0, 0.1);
            content: '';
            height: 24px;
            width: 2px;
            left: 0;
            position: absolute;
            top: 8px;
            transform: rotate(57.5deg);
            transform-origin: 50% 100%;
        }
    }

    .recorrente {
        position: absolute;
        top: 2px;
        right: 7px;
        text-shadow: 0 0 1px white;
        color: rgb(77, 77, 77);
    }

    .reagendado {
        margin-right: 3px;
    }
}