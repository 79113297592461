.painel-consulta {

    .card-header {
        border: none;
        background: none;
    }

    .parametros {

        display: flex;
        flex-direction: column;
        gap: 10px;

        .parametros-inicial {
            display: flex;
            align-items: center;

            .botao-cadastrar {
                // flex-grow: 1;
                white-space: nowrap;
            }

            .campo-consulta {
                // flex-grow: 1;
            }

            .campo-flags {
                flex-grow: 1;
                white-space: nowrap;
            }

            > * {
                margin-right: 10px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .parametros-campos {
            display: flex;

            > * {
                width: calc(100% / 3);
            }
        }

        .parametros-filtros-eventos {

            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin: 10px 0px;

        }

    }

    .card-body {
        position: relative;
    }

    

}