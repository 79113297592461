
.registros {

    .registro {
        border-bottom: 1px solid rgb(230, 230, 230);

        &:last-child {
            border-bottom: none;
            margin-bottom: 0 !important;
        }
    }

    
}

.linkCarregarMais {

    background: none;
    border: none;
    box-shadow: none;

    &:focus {
        outline: 0;
    }
    
    &:hover {
        color: blue;
        text-decoration: underline;
        cursor: pointer;
    }
}