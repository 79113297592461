.arquivo {

    // variaveis
    --width-arquivo: calc(100% - 6px);

    width: var(--width-arquivo);
    display: inline-flex;
    align-items: flex-start;
    margin-right: 6px;
    margin-bottom: 6px;
    // min-width: 300px;
    // width: calc((25em - 100%) * 1000);
    position: relative;
    vertical-align: middle;

    &:hover {
        // cursor: pointer;
        background: rgb(245, 245, 245);

        .descricao {

            .opcoesArquivo {
                display: block;
                // vertical-align: middle;
            }
        }
    }

    .icone {
        width: 50px;
        height: 50px;
        object-fit: contain;
        vertical-align: middle;
    }

    .descricao {
        width: calc( 100%);
        display: block;
        
        .nome {
            display: block;
            width: calc( 100% - 50px );
        }

        .opcoesArquivo {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 3px;
            // display: none;

            .opcao {
                background: none;
                border: 0;
                box-shadow: 0;

                &:hover {
                    background: rgb(226, 226, 226);

                    .iconeOpcao {
                        color: rgb(54, 54, 54);
                    }
                }

                &:focus {
                    outline: 0;
                }

                .iconeOpcao {
                    font-size: 14px;
                    color: rgb(97, 97, 97);
                }
            }
        }

    }



    
}
