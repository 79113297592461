

.apresentar {

    text-align: center;
    font-size: 1.2rem;

    .titulo {
        font-weight: bold;
        font-size: 2rem;
    }
}


.apolice {

    .botaoEntrar {
        box-shadow: none;
        // font-weight: normal;
        margin-left: 10px;
        background-color: #284787;
        color: rgb(226, 226, 226);
        white-space: nowrap;
    
        &:hover {
            color: white;
        }
    }
    
}



.lembrarCodigo {
    text-align: center;
    color: white;
    // text-decoration: none;
    
    button {
        color: rgb(226, 226, 226);;

        &:hover {
            color: white;
            text-decoration:underline;
        }
    }
}

.bgRoca {
    
    p {
        color: white;
        text-align: center;
    }

    .botaoSolicitar {
        box-shadow: none;
        // font-weight: normal;
        margin-left: 10px;
        background-color: #284787;
        color: rgb(226, 226, 226);
        white-space: nowrap;
    
        &:hover {
            color: white;
        }
    }
}

.bgRocaModal {
    background-color: #666666 !important;
}