.containerImagem {
    position: relative;

    .carregandoImagem {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(128, 128, 128, 0.61);
        border-radius: 0.25rem !important;
        padding: 4px;
        display: flex;
        align-items: center;
        .barra {
            background: rgb(177, 177, 177);
            border-radius: 3px;
            height: 30px;
            width: 100%;
            text-align: center;
            color: white;
            border: 1px solid black;
        }
    }

}

