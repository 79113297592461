.option {
    margin: 0;
    padding: 2px 6px;
    p {
        margin: 0;
    }
    border-bottom: 1px solid rgb(228, 228, 228);

    .dadosUsuario {
        display: flex;

        img {
            max-width: 50px;
            height: 50px;
            width: 50px;
            margin-right: 5px;
            object-fit: cover;
        }
    }

    &:hover {
        background: rgb(82, 82, 255);
        color: white;

        &.desabilitar {
            background: rgb(228, 228, 228);
            color: rgb(139, 139, 139);
        }
    }
}

.focado {
    background: rgb(213, 213, 255);

    &.desabilitar {
        background: rgb(228, 228, 228);
    }

    &.novo {
        background: rgb(174, 255, 174);
        font-weight: bold;
    }
}

.desabilitar {
    color: rgb(139, 139, 139);
}

.novo {
    background: rgb(209, 255, 209);
    font-weight: bold;
}