
.ficha {

    .dataCadastro {
        font-weight: normal;
        font-size: 0.8rem;
    }

    .tag {
        margin-right: 5px;
        font-size: 12px;
        font-weight: normal;
        font-weight: bold;

        &:first-child {
            border-top-left-radius: 50rem !important;
            border-bottom-left-radius: 50rem !important;
        }
        
        &:last-child {
            border-top-right-radius: 50rem !important;
            border-bottom-right-radius: 50rem !important;
        }

        &.tagCotacao {
            background: rgb(215, 215, 2);;
        }

        &.tagRenovacao {
            background: rgb(169, 48, 169);
        }

        &.tagProposta {
            background: rgb(95, 95, 255);
        }

        &.tagEmitido {
            background: rgb(51, 217, 51);

            &.finalizado {
                background: rgb(80, 80, 80);
            }
        }
    }

    .opcoes {
        text-align: right;
        display: flex;
        justify-content: flex-end;

        button {
            background: none;
            border: 0;
            height: auto;
            color: rgb(168, 168, 168);

            &:hover {
                color: rgb(63, 63, 63);
            }

            &:focus {
                outline: 0;
            }
        }
    }
}