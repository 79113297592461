// .eventos {
//     display: flex;
//     align-items: flex-start;

// }

.eventos {
    column-count: 3;
    column-gap: 5px;
    row-gap: 5px;
    box-sizing: inherit;
    position: relative;
}

@media (max-width: 1100px) {
    .eventos {
        column-count: 2;
    }
}

@media (max-width: 830px) {
    .eventos {
        column-count: 1;
    }
}