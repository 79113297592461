.buscar {
    position: absolute !important;
    right: calc(30% + 5rem);
    top: 0.5rem;
    // background: red;
    width: 30%;
    min-width: 200px;
    margin: auto;

    // .corpo {
    //     display: flex;
    //     align-items: center;
    // }

    .botaoFiltro {
        
    }

    .combo {
        flex-grow: 1;
    }

}