.responsavel {
    font-size: 0.6rem;
}

.foto {
    height: 35px;
    width: 35px;
    object-fit: cover;
    // margin-left: 5px;
    border-radius: 20px;
    // border: 2px solid rgb(192, 192, 192);
    box-shadow: 0 0 2px grey;
    border-radius: 35px;
}

.iconeUsuario {
    // margin-left: 5px;
    font-size: 35px;
    color: rgb(175, 175, 175);
    border: 2px solid rgb(192, 192, 192);
    border-radius: 35px;
}