.cardProximidade {
    position: absolute;
    top: 3.5rem;
    left: 0.5rem;
    width: 25%;

    .cardCabecalho {

        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 1px;
        background-color: #FFF;

        .tituloLista {
            margin: 5px;
        }

        .btMinimizar {

            font-size: 20px;
            padding: 5px 12px;
            font-size: larger;
            margin-bottom: 0px;
            margin-block-end: 0px;
            border-radius: 2px;
            display: flex;
            margin: 5px;
            text-align: right;
            background: transparent;
            cursor: pointer;
    
            &:hover {
    
                background: rgba(0, 0, 0, 0.4);
    
            }

            &:active {

                background: rgba(0, 0, 0, 0.2);

            }
    
        }

    }    

    .cardCorpo {

        padding: 1px;
        max-height: 75vh;
        overflow: hidden;

        .registros {
            
            overflow-y: auto;
            max-height: 75vh;
    
            
            &::-webkit-scrollbar-track {
                -webkit-box-shadow: none;
                border-radius: 3px;
                background-color: #efefef;
            }
        
            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
                border-radius: 3px;
            }
        
            &::-webkit-scrollbar-thumb {
                background: #c7c7c7;
                border-radius: 3px;
            }
    
            .registro {

                cursor: pointer;

                &:hover {
                    background: #bdbdbd !important;
                }
    
                &.selecionado {
                    background: #e3ffc9 !important;
                }
            }
        }

    }

    .cardRodape {

        background-color: #FFF;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

    }

}