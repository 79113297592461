


@media (max-width: 450px) {

    .inputconsulta {
        // display: none !important;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .labelCpf {

            .labelTexto {
                width: 100%;
                border-radius: 3px !important;
            }
        }

        .campoCpf {
            width: 100% !important;
            margin-top: 3px !important;
            border-radius: 3px !important;
        }

        .botaoCpf {
            text-align: center !important;
            width: 100% !important;
            margin-top: 3px !important;
            display: block;

            .botao {
                border-radius: 3px !important;
            }
        }

    }

}