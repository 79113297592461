.dia {

    &:first-child {

        .diaLabel {
            display: flex;
            align-items: 'center';
    
            .labelIcone {

                .linha {
                    top: 50%;
                }
            }
        }

    }
        
    .diaLabel {
        display: flex;
        align-items: 'center';

        .labelIcone {
            width: 20%;
            position: relative;

            .circulo {
                position: absolute;
                background: rgb(82, 82, 233);
                // border: 2px solid rgb(82, 82, 233);
                border-radius: 20px;
                width: 30px;
                height: 30px;
                right: 0;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                display: flex;
                align-items: center;
                justify-items: center;
                color: white;
                font-weight: bold;

                span {
                    margin: auto;
                }
            }

            .linha {
                position: absolute;
                background: #5252e9;
                width: 2px;
                height: 100%;
                right: 0;
                left: 0;
                top: 0;
                top: 50%;
                margin: auto;
            }

            .linhaHorizontal {
                position: absolute;
                background:rgb(82, 82, 233);
                // border: 3px solid red;
                // border-radius: 20px;
                width: 50%;
                height: 2px;
                right: 0;
                left: 50%;
                // top: 0;
                bottom: 0;
                // margin: auto;
            }
        }

        .labelDescricao {
            width: 80%;
            min-height: 60px;
            display: flex;
            align-items: center;

            // border-bottom: 1px solid #ff5959;
            // border-bottom-right-radius: 20px;
        }
    }
}