.tooltip {
    font-size: 0.8rem;
    max-width: 200px;
    min-height: 0;
}

.legendaPizza {
    color: white;
    text-shadow: 0 0 3px grey;
    min-width: 32px;
}