.comboCheckbox {
    // background: #f5f5f5;
    display: block;
    height: 150px;
    position: relative;

    .comboContainer {
        height: 100%;
        overflow: auto;

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: none;
            border-radius: 3px;
            background-color: #efefef;
        }
    
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            border-radius: 3px;
        }
    
        &::-webkit-scrollbar-thumb {
            background: #c7c7c7;
            border-radius: 3px;
        }

        .checkbox {

            &:hover {
                
                background: rgb(224, 224, 224);
                border-radius: 3px;
                cursor: pointer;

                label {
                    width: 100%;
                    cursor: pointer;
                }

                input {
                    cursor: pointer;
                }
            }
        }
    }
}