.arquivo {

    // variaveis
    --width-arquivo: calc(50% - 6px);

    width: var(--width-arquivo);
    display: inline-flex;
    align-items: flex-start;
    margin-right: 6px;
    margin-bottom: 6px;
    // min-width: 300px;
    // width: calc((25em - 100%) * 1000);
    position: relative;
    vertical-align: middle;

    &:hover {
        // cursor: pointer;
        background: rgb(245, 245, 245);

        .descricao {

            .opcoesArquivo {
                display: block;
                // vertical-align: middle;
            }
        }
    }

    &.salvando {
        position: relative;
        background: rgb(245, 246, 255);

        &:hover {
            cursor: default;
        }

        .barra {
            position: absolute;
            top: 0;
            bottom:0;
            left: 0;
            right: 0;
            background: rgba(43, 255, 0, 0.58);
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
            text-shadow: 1px 1px 2px grey;
            min-width: 35px;
            
            span {
                color: #323232;
            }

            &.erro {
                background: #dc3546ab !important;
            }
        }
    }

    .icone {
        width: 50px;
        height: 50px;
        object-fit: contain;
        vertical-align: middle;
    }

    .descricao {
        width: calc( 100%);
        display: block;
        
        .nome {
            display: block;
            width: calc( 100% - 50px );
        }

        .opcoesArquivo {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: 3px;
            display: none;

            .opcao {
                background: none;
                border: 0;
                box-shadow: 0;

                &:hover {
                    background: rgb(226, 226, 226);

                    .iconeOpcao {
                        color: rgb(54, 54, 54);
                    }
                }

                &:focus {
                    outline: 0;
                }

                .iconeOpcao {
                    font-size: 14px;
                    color: rgb(97, 97, 97);
                }
            }
        }

    }

    .opcaoExclusao {
        font-size: 12px;
        line-height: 17px;

        span {
            color: #ebebeb;
            vertical-align: middle;
            font-weight: 300;
        }

        .iconeOpcaoExcluir {
            vertical-align: middle;
            margin-right: 3px;
        }
    }

    &.small {
        --width-arquivo: 100% !important;
        font-size: 0.8rem;
        margin-right: 0;

        .icone {
            width: 40px;
            height: 40px;
        }

    }

    
}

@media only screen and (max-width: 950px) {
    .arquivo {
        --width-arquivo: 100%;
        margin-right: 0;
    }
}