.Toastify__toast-container {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 85vh;
}

.Toastify__toast-container::-webkit-scrollbar {
    width: 5px;
    background: #FFF;
    border-radius: 8px;
}

.Toastify__toast-container::-webkit-scrollbar-thumb {
    background: #000;
    border-radius: 8px;
}