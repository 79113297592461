.eventos {
    column-count: 4;
    column-gap: 0px;
    box-sizing: inherit;
    position: relative;
}

.carregador {
    position: absolute;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    font-size: 12px;
    box-shadow: 0 0 6px black;
    // text-shadow: 0 0 5px black;
    color: #eeeeee;
    transition: width 1s linear;

    .icone {
        margin-right: 5px;
    }
}