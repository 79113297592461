
.botao {
    background: none;
    border: none;
    box-shadow: none;
    color: #6d6d6d;
    position: relative;

    &:active, &:focus {
        outline: 0;
    }

    &:hover {
        color: black;
    }

    &.ativo {

        color: #219221;
    }

    .tooltip {
        display: none;
    }
}