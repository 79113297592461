.opcoes {
    // display: flex;
    display: none;
    // position: absolute;
    bottom: 0;
    left: 0;
    // background: rgba(0, 0, 0, 0.034);
    padding: 3px;
    // border-top-right-radius: 3px;
    justify-content: space-evenly;
    width: 100%;
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.712);

    

    > * {
        
        // margin-right: 6px;


        &:last-child {

            // margin-right: 0;
        }

    }


    button {
        // display: none;
        height: auto;
        background: none;
        border: 0;
        color: rgba(59, 59, 59, 0.411);
        color: rgb(202, 202, 202);
        padding: 0;
        margin: 0;
        // filter: drop-shadow(0 0 10px white);
        font-weight: bold;
        &:focus {
            outline: 0;    
        }

        &:last-child {
            // margin-right: 0;
        }

        .icone {
            // font-size: 15px;
        }

        &:hover {
            color: rgb(37, 37, 37);
            color: rgb(253, 253, 253);
        }
    }
    

}