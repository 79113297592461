.horario {

    .horarioLabel {
        display: flex;
        align-items: 'center';

        .labelIcone {
            width: 20%;
            position: relative;

            .taghoras {
                position: absolute;
                background: #ededff;
                border-radius: 5px;
                width: 80px;
                height: 25px;
                text-align: center;
                right: 46px;
                left: 0;
                top: 35px;
                margin: auto;
                display: flex;
                justify-items: center;
                align-items: center;
                justify-content: center;
                font-size: 0.8rem;
                font-weight: bold;
                color: #3f3f3f;
            }

            .circulo {
                position: absolute;
                background: #dbdbdb;
                // border: 2px solid rgb(82, 82, 233);
                border-radius: 5px;
                width: 10px;
                height: 10px;
                right: 0;
                left: 0;
                // top: 0;
                top: 16px;
                margin: auto;
                display: flex;
                align-items: center;
                justify-items: center;
                color: white;
                font-weight: bold;

                span {
                    margin: auto;
                }
            }

            .linha {
                position: absolute;
                background: #5252e9;
                width: 2px;
                height: 100%;
                right: 0;
                left: 0;
                top: 0;
                bottom: 0;
                margin: auto;
            }

            .linhaHorizontal {
                position: absolute;
                // background:#dbdbdb;
                border-top: 1px solid #dbdbdb;
                // border: 3px solid red;
                // border-radius: 20px;
                width: 60%;
                // height: 1px;
                right: 0;
                left: 40%;
                // top: 0;
                top: 20px;
                margin: auto;
                height: 20px;
                border-top-right-radius: 20px;

            }
        }

        .labelDescricao {
            width: 80%;
            min-height: 60px;
            display: flex;
            align-items: center;
            padding: 0 10px;
            padding-bottom: 10px;

            // border-bottom: 1px solid #ff5959;
            // border-bottom-right-radius: 20px;
        }
    }
}