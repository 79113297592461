.container {
    margin-bottom: 30px;
}

.foto {
    height: 50px;
    min-width: 50px;
    width: 50px;
    object-fit: cover;
    border-radius: 20px;
    // border: 2px solid rgb(192, 192, 192);
    box-shadow: 0 0 2px grey;
    border-radius: 50px;
}

.iconeUsuario {
    font-size: 50px;
    color: rgb(175, 175, 175);
    border: 2px solid rgb(192, 192, 192);
    border-radius: 50px;
    height: 50px;
    width: 50px;
}

.nomeUsuario {
    font-size: 0.8rem;
    padding-left: 5px;
    border-bottom: 1px solid #d1d1d1;
}


.funcoes {
    // // background: red;    
    // display: flex;
    // // align-items: center;
    // flex-wrap: wrap;

    // -webkit-column-count: 4;
    // -moz-column-count: 4;
    // column-count: 4;
    // grid-column-gap: 0px;
    // -webkit-column-gap: 0px;
    // -moz-column-gap: 0px;
    // column-gap: 0.50rem;
    // box-sizing: inherit;
    // position: relative;


}