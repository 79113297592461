.abas {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .aba {
        position: relative;
        background: none;
        border: none;
        box-shadow: 0;
        display: inline-flex;
        align-items: center;
        color: #9c9c9c;
        text-shadow: 1px 1px 5px #d1d1d1;

        &:hover {
            border-color: rgb(219, 219, 219);
            border-bottom: 1px solid rgb(219, 219, 219);
        }

        &:after {
            content: '';
            position: absolute;
            // top: 0;
            bottom: 0;
            right: 0;
            height: 40%;
            width: 1px;
            // background: rgb(204, 204, 204);
            margin: auto;
            display: none;
            border-right: 1px solid rgb(204, 204, 204);
            border-bottom-right-radius: 3px;
        }

        &:last-child {
            border-right: 0;
        }

        &:focus {
            outline: 0;
        }

        &:disabled {
            text-decoration: line-through;
            font-weight: lighter; 
        }

        .icone {
            // margin-right: 3px;
            font-size: 14px;
        }

    //     // bottom: -1px;
    //     position: relative;
    //     border-bottom-left-radius: 0;
    //     border-bottom-right-radius: 0;
    //     box-shadow: none !important;
    //     box-shadow: 1px -1px 1px rgb(221, 221, 221) !important;
    //     margin-right: 3px;
    //     z-index: 0;
    //     border-bottom: 0;

        &.ativo {
            color: rgb(51, 51, 51);
    //         bottom: -1px;
    //         box-shadow: none !important;
    //         border: 1px solid rgb(209, 209, 209);
    //         border-bottom: 0;
    //         background: #eeeef1;

            border-bottom: 1px solid rgb(204, 204, 204);
            border-bottom-right-radius: 3px;
            &:after {
                display: block;
            }
        }
    }
}