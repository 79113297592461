.funcao {
    // // background: blue;
    // display: inline-flex !important;
    // align-items: center;
    // justify-content: center;
    // // padding: 2px;
    // width: 100%;
    // break-inside: avoid-column;
    // -webkit-column-break-inside: avoid;
    // position: relative;

    .evento {
        font-size: 0.8rem;
    }
    
    .funcaoDescricao {
        // background: grey;
        display: inline-flex;
        justify-content: center;

        .icone {
            width: 20px;
            height: 20px;
            font-size: 20px;
            margin-right: 10px;
        }

        .imagem {
            width: 30px;
            min-width: 30px;
            height: 30px;
            font-size: 30px;
            border-radius: 15px;
            margin-right: 10px;
            object-fit: contain;

            &.redirecionar {
                cursor: pointer;
            }
        }

        .texto {
            text-align: left;
            font-size: 0.8rem;
            // max-width: 90px;
            word-break: break-word;
        }
    }
}