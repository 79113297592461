.modal-content {
    min-height: 35vh !important;
    max-height: 85vh !important;
    overflow-y: auto !important;
}

.valor-total {

    .label {

        font-size: 20px;
        
    }

    .valor {

        color: green;
        font-size: 20px;

    }

}

.rodape {

    align-items: center !important;
    justify-content: space-between !important;
    width: 100%;

}