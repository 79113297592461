.botao-filtro-evento {

    text-decoration: none;
    min-width: 150px;
    border: 1px solid gray;
    background-color: unset;
    display: flex;
    gap: 10px;
    background-color: #dcdcdc;
    border-radius: 5px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0px;
    padding: 10px 15px;
    transition: box-shadow 0.2s ease;

    &.selecionado {
        box-shadow: 0px 0px 12px 0px black;
    }

    &:hover {
        opacity: 0.8;
    }

    &:active {
        opacity: 0.6;
    }

    svg {
        width: 20px;
        height: 20px;
        margin: 0px;
        padding: 0px;        
        border: 0.5px solid black;
        border-radius: 999px;

        path {
            filter: drop-shadow(2px 4px 30px black);
        }
    }

    p {
        margin: 0px;
    }

    .texto-filtro-selecionado {
        font-weight: bold;
    }

}